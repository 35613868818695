export default {
	data: () => ({
		langEs: {
			linkHome: 'Inicio',
			title:'Especialistas',
			btn: 'Pedir cita',
      		btn2: 'Shop',
			selectTitle: 'Elige una fecha para el registro',
			price: 'Consulta de',
			reload: 'Cargar más',
			result: 'Resultados',
			search: 'Buscar',
			address: 'Dirección',
			certificateTitle: {
				text: 'Certificados y ',
				color: 'diplomas'
			}
		}
	})
}
